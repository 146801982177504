import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-gigs',
  templateUrl: './gigs.component.html',
  styleUrls: ['./gigs.component.scss']
})
export class GigsComponent implements OnInit {
  public gigs: Observable<any[]>;
  showSpinner = true;

  constructor(db: AngularFirestore) {
    this.gigs = db.collection<any>('event', ref => ref.orderBy('stagetime')).valueChanges().pipe(
      map((gigs) => gigs.filter((gig) => {
        const gigDay = moment(gig.stagetime.seconds*1000).startOf('day').toDate();
        const startOfToday = moment().startOf('day').toDate();
        return gigDay >= startOfToday;
      }))
    );
  }

  ngOnInit() {
    this.gigs.subscribe(() => this.showSpinner = false)
  }
}
