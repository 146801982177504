<footer class="footer">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li> <a [routerLink]="'/impressum'">Impressum</a></li>
                    <li> <a [routerLink]="'/privacy'">Datenschutz</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{test | date: 'yyyy'}} The TearAparts
                </span>
            </div>
        </div>
    </div>
</footer>
