<div id="carousel">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
                <div class="card page-carousel">
                    <ngb-carousel [interval]="3600000">
                        <ng-template ngbSlide>
                            <object width="100%" height="500px"
                                    data="https://www.youtube.com/v/wAUvAYnQOcE">
                            </object>
                        </ng-template>
                        <ng-template ngbSlide>
                            <object width="100%" height="500px"
                                    data="https://www.youtube.com/v/nV1UsVDUaro">
                            </object>
                        </ng-template>
                        <ng-template ngbSlide>
                            <object width="100%" height="500px"
                                    data="https://www.youtube.com/v/_3wO2wOHey8">
                            </object>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
