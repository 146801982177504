import { Component, OnInit } from '@angular/core';
import {BandMemberModel} from './band-member.model';

@Component({
  selector: 'app-band',
  templateUrl: './band.component.html',
  styleUrls: ['./band.component.scss']
})
export class BandComponent implements OnInit {
  public members: BandMemberModel[];

  constructor() {
    this.members = [
      new BandMemberModel('Christoph', 'Vocals', './assets/img/band/vox.png'),
      new BandMemberModel('Jan', 'Drums', './assets/img/band/drums.png'),
      new BandMemberModel('Thomas', 'Guitar', './assets/img/band/guitar.png'),
      new BandMemberModel('Matze', 'Bass', './assets/img/band/bass.png'),
      new BandMemberModel('Aleks', 'Keys', './assets/img/band/keys.png'),
      new BandMemberModel('Ehmann', 'Trumpet', './assets/img/band/trumpet.jpg'),
      new BandMemberModel('Denise', 'Saxophone'),
      new BandMemberModel('Viktor', 'Trombone')
    ]
  }

  ngOnInit() {
  }

}
