import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-band-info',
  templateUrl: './band-info.component.html',
  styleUrls: ['./band-info.component.scss']
})
export class BandInfoComponent implements OnInit {

  public description: Observable<string>
  public title: Observable<string>

  constructor(db: AngularFirestore) {
    this.description = db.collection<any>('band-info', ref => ref).valueChanges().pipe(
      map((bandInfo) => bandInfo[0].description)
    );
    this.title = db.collection<any>('band-info', ref => ref).valueChanges().pipe(
      map((bandInfo) => bandInfo[0].title)
    );
  }

  ngOnInit() {
  }

}
