import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  contactFormVisible$: Observable<boolean>
  private contactFormVisibleSubject = new Subject<boolean>();

  constructor() {
    this.contactFormVisible$ = this.contactFormVisibleSubject.asObservable();
  }

  contactFormVisible(data: boolean) {
    this.contactFormVisibleSubject.next(data);
  }
}
