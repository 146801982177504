import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { ContactFormModel } from '../../models/contact-form-model';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private db: AngularFirestore) {
  }

  submit(contactForm: ContactFormModel) {
    this.db.collection("contact-form").add({
      name: contactForm.name,
      mail: contactForm.mail,
      message: contactForm.message
    });
  }
}
