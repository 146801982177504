<app-header></app-header>
<div class="main">
    <div class="section-dark text-center" id="band-info">
        <app-band-info></app-band-info>
    </div>

    <div id="gigs" class="section section-dark text-center">
        <app-gigs></app-gigs>
    </div>

    <!--<div id="band" class="section section-dark text-center">
        <app-band></app-band>
    </div>-->

    <div id="youtube" class="section section-dark text-center landing-section">
        <app-video></app-video>
    </div>

    <div id="music" class="section text-center landing-section">
        <app-soundcloud></app-soundcloud>
    </div>
    
    <!--<div id="contact" class="section section-dark landing-section">
        <app-contact *ngIf="contactFormVisible"></app-contact>
        <app-contact-reply *ngIf="!contactFormVisible"></app-contact-reply>
    </div>-->
</div>
