<div class="container">
  <h2 class="title">Bandmitglieder</h2>
  <div class="row">
    <div *ngFor="let member of members" class="col-md-3 col-sm-6">
      <div class="card card-profile card-plain">
        <div class="card-avatar">
          <a href="#avatar"><img src="{{member.image}}" alt="{{member.instrument}}"></a>
        </div>
        <div class="card-block">
          <a href="#paper-kit">
            <div class="author">
              <h4 class="card-title">{{member.name}}</h4>
              <h6 class="card-category">{{member.instrument}}</h6>
            </div>
          </a>
          <p class="card-description text-center">
            {{member.description}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

