import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { LandingComponent } from './landing/landing.component';
import {FacebookModule} from 'ngx-facebook';
import {ComponentsModule} from '../components/components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        FacebookModule,
        ComponentsModule,
        NgbModalModule
    ],
    declarations: [
        LandingComponent
    ]
})
export class ExamplesModule { }
