import { Component, OnInit } from '@angular/core';
import { ContactFormModel } from '../../models/contact-form-model'
import { ContactFormService } from '../../services/contact-form/contact-form.service';
import {DataSharingService} from '../../services/data-sharing/data-sharing.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  focus: any;
  focus1: any;
  public contactForm: ContactFormModel;

  constructor(private contactFormService: ContactFormService, private dataSharingService: DataSharingService) {
    this.contactForm = new ContactFormModel();
  }

  ngOnInit() {
  }

  submission() {
    this.contactFormService.submit(this.contactForm);
    this.dataSharingService.contactFormVisible(false);
  }
}
