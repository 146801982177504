<div class="container">
  <div class="row">
    <div class="col-md-8 mr-auto ml-auto">
      <h2 class="title">Vielen Dank für Deine Nachricht</h2>
      <h5 class="description">
        Wir werden uns zeitnah bei Dir melden.
      </h5>
    </div>
  </div>
</div>
