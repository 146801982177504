import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './pages/landing/landing.component';
import {GigsComponent} from './components/gigs/gigs.component';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {PrivacyComponent} from './components/privacy/privacy.component';

const routes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing',          component: LandingComponent },
    { path: 'gigs',             component: GigsComponent},
    { path: 'impressum',        component: ImpressumComponent},
    { path: 'privacy',          component: PrivacyComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
