import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soundcloud',
  templateUrl: './soundcloud.component.html',
  styleUrls: ['./soundcloud.component.scss']
})
export class SoundcloudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
