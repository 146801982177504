<div class="page-header" data-parallax="true" style="background-image: linear-gradient(to top, rgb(11,16,16) 0%, rgba(0,0,0,0) 70%), url('./assets/img/bg/bg.jpg');">
  <div class="filter"></div>
  <!--<div class="container">
    <div class="title-brand">
      <h1 class="text-center"><img class="header--logo" src="assets/img/tearaparts/logo.png" alt=""></h1>
    </div>
  </div>-->
  <div class="moving-clouds" style="background-image: url('assets/img/clouds.png'); "></div>
  <div class=" category-absolute ml-auto mr-auto">
    <div class="motto text-center">
      <a class="btn btn-outline-neutral btn-round btn-sm" rel="tooltip" title="Like us on Facebook" data-placement="bottom" href="https://www.facebook.com/tearaparts/" target="_blank">
        <i class="fa fa-facebook-square"></i>
        <span class="button-text">
          Facebook
        </span>
        <!--<p class="d-lg-none"></p>-->
      </a>
      <a class="btn btn-outline-neutral btn-round btn-sm" rel="tooltip" title="Follow us on Instagram" data-placement="bottom" href="https://www.instagram.com/thetearaparts" target="_blank">
        <i class="fa fa-instagram"></i>
        <span class="button-text">
          Instagram
        </span>
        <!--<p class="d-lg-none"></p>-->
      </a>
      <a class="btn btn-outline-neutral btn-round btn-sm" rel="tooltip" title="Subscribe us on Youtube" data-placement="bottom" href="https://www.youtube.com/channel/UC-xUB04HBvGYFI6oQ-zTSwg" target="_blank">
        <i class="fa fa-youtube"></i>
        <span class="button-text">
          Youtube
        </span>
        <!--<p class="d-lg-none"></p>-->
      </a>
    </div>
  </div>
</div>
