<div class="container">
  <div class="title">
    <h2>Kontakt</h2>
  </div>
  <div class="row">
    <div class="col-md-8 mr-auto ml-auto">
      <form class="contact-form" (ngSubmit)="submission()">
        <div class="row">
          <div class="col-md-6">
            <label>Name</label>
            <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="nc-icon nc-single-02"></i></span>
              </div>
                <input type="text" class="form-control" placeholder="Name" (focus)="focus=true" (blur)="focus=false" [(ngModel)]="contactForm.name" name="name">
            </div>
          </div>
          <div class="col-md-6">
            <label>Email</label>
            <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
              <div class="input-group-prepend">
                <span class="input-group-text">  <i class="nc-icon nc-email-85"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Email" (focus)="focus1=true" (blur)="focus1=false" [(ngModel)]="contactForm.mail" name="mail">
            </div>
          </div>
        </div>
        <label>Nachricht</label>
        <textarea class="form-control" rows="4" placeholder="" [(ngModel)]="contactForm.message" name="message"></textarea>
        <div class="row">
          <div class="col-md-4 mr-auto ml-auto">
            <button class="btn btn-danger btn-lg btn-fill" type="submit">Senden</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
