<nav class="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" [routerLink]="['/']"><img class="nav--logo" [src]="'assets/img/tearaparts/logo.png'"></a>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngIf="!isHome()">
                    <a [routerLink]="['/']" class="nav-link"><i class="nc-icon nc-home"></i>Home</a>
                </li>
                <!--<li class="nav-item">
                    <a [routerLink]="['/']" fragment="band-info" class="nav-link"><i class="nc-icon nc-home"></i>band</a>
                </li>-->
                <li class="nav-item">
                    <a [routerLink]="['/']" fragment="gigs" class="nav-link"><i class="nc-icon nc-home"></i>termine</a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/']" fragment="music" class="nav-link"><i class="nc-icon nc-home"></i>musik</a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/']" fragment="contact" class="nav-link"><i class="nc-icon nc-home"></i>kontakt</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
