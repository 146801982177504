export class BandMemberModel {
    name: string;
    instrument: string;
    image: string;
    description: string;

    constructor(name: string, instrument: string, image = './assets/img/band/placeholder.png', description = '') {
        try {
            this.name = name;
            this.instrument = instrument;
            this.image = image;
            this.description = description;
        } catch (e) {
            console.log(e);
        }
    }
}
