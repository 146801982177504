<div class="container">
  <div class="row">
    <div class="col-md-8 mr-auto ml-auto">
      <h2 class="title">{{ title | async }}</h2>
      <h5 class="description">{{ description | async }}</h5>
      <br>
    </div>
  </div>
</div>
<br>
