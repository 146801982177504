import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-reply',
  templateUrl: './contact-reply.component.html',
  styleUrls: ['./contact-reply.component.scss']
})
export class ContactReplyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
