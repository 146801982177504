import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {DataSharingService} from '../../services/data-sharing/data-sharing.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  public contactFormVisible = true;

  constructor(private dataSharingService: DataSharingService) { }

  ngOnInit() {
      this.dataSharingService.contactFormVisible$.subscribe(
          res => {
              this.contactFormVisible = res;
      });
  }

}
