import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';

import { NavigationComponent } from './navigation/navigation.component';
import { NotificationComponent } from './notification/notification.component';
import { BandComponent } from './band/band.component';
import { ContactComponent } from './contact/contact.component';
import { GigsComponent } from './gigs/gigs.component';
import { HeaderComponent } from './header/header.component';
import { BandInfoComponent } from './band-info/band-info.component';
import { ContactReplyComponent } from './contact-reply/contact-reply.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SoundcloudComponent } from './soundcloud/soundcloud.component';
import { VideoComponent } from './video/video.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        ReactiveFormsModule,
        HttpClientModule,
        EmbedVideo.forRoot()
    ],
    declarations: [
        NavigationComponent,
        NotificationComponent,
        BandComponent,
        ContactComponent,
        GigsComponent,
        HeaderComponent,
        BandInfoComponent,
        ContactReplyComponent,
        ImpressumComponent,
        PrivacyComponent,
        SoundcloudComponent,
        VideoComponent
    ],
    entryComponents: [],
    exports: [
        BandComponent, ContactComponent, GigsComponent, HeaderComponent, BandInfoComponent, ContactReplyComponent, SoundcloudComponent, VideoComponent
    ]
})
export class ComponentsModule { }
